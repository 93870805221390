import Vue from 'vue';
import App from './App.vue';
import store from './store';
import i18n from './i18n';
import './filters';

require('ne01-common');

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
  i18n,
}).$mount('#app')
