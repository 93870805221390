/*export function hasOwnNestedProperty(obj, propertyPath) {
  if (!obj || !propertyPath)
    return false;

  const properties = propertyPath.split('.');

  for (let i = 0; i < properties.length; i++) {
      const prop = properties[i];
      if (!obj || !obj.hasOwnProperty(prop)) {
          return false;
      } else {
          obj = obj[prop];
      }
  }

  return true;
}*/

export function normalizeValue(value, min, max) {
  const normalizedValue = ((value - min) * (1 - 0)) / (max - min) + 0;
  return normalizedValue > 1 ? 1 : normalizedValue;
}
