import Vue from 'vue';

export default {
  initializeApp(state, payload) {
    Vue.set(state, 'visitorId', payload.visitorId || 1);
    Vue.set(state, 'lang', payload.lang);
  },

  loadCmsData(state) {
    Vue.set(state, 'cmsData', null);
    Vue.set(state, 'cmsDataError', null);
    Vue.set(state, 'cmsDataLoading', true);
  },
  loadCmsDataSuccess(state, payload) {
    Vue.set(state, 'cmsData', payload);
    Vue.set(state, 'cmsDataLoading', false);
  },
  loadCmsDataFailed(state, payload) {
    Vue.set(state, 'cmsDataError', payload);
    Vue.set(state, 'cmsDataLoading', false);
  },

  loadVisitorDataSuccess(state, payload) {
    Vue.set(state, 'visitorData', payload);
  },
  loadVisitorDataFailed(state, payload) {
    Vue.set(state, 'visitorDataError', payload);
  },

  saveRecordFailed(state, payload) {
    Vue.set(state, 'saveDataError', payload);
  },

  updateCurrentStep(state, payload) {
    const step = payload ? payload : state.currentStep + 1;
    let selectableSourcesLeft, selectableSourcesRight;
    Vue.set(state, 'currentStep', step);

    switch (step) {
      case 2: {
        selectableSourcesLeft = state.selectableSourcesLeft;
        selectableSourcesRight = state.selectableSourcesRight;
        state.cmsData.birds.map(item => {
          selectableSourcesLeft.push({
            id: item.bird.ID, 
            type: 'comparable',
            title: item.bird.post_title,
            img: item.bird.img, 
            src: item.bird.soundtrack 
          })
        });
        /* add a bird to currentTracks - hardcoded */
        let tracks = state.currentTracks;
        tracks.splice(0, 1, selectableSourcesLeft[0]);
        Vue.set(state, 'currentTracks', tracks);
        /* */
        Vue.set(state, 'selectableSourcesLeft', selectableSourcesLeft);
        Vue.set(state, 'selectableSourcesRight', selectableSourcesRight);
        break;
      }
      case 4: {
        selectableSourcesLeft = state.selectableSourcesLeft;
        selectableSourcesRight = state.selectableSourcesRight;
        selectableSourcesLeft = [];
        state.cmsData.background_sounds.map(item => {
          selectableSourcesLeft.push({
            id: item.backgound_sound.ID, 
            type: 'comparable',
            title: item.backgound_sound.post_title,
            img: item.backgound_sound.img, 
            src: item.backgound_sound.soundtrack 
          })
        });
        state.cmsData.birds2.map(item => {
          selectableSourcesRight.push({
            id: item.bird.ID, 
            type: 'comparable',
            title: item.bird.post_title,
            img: item.bird.img, 
            src: item.bird.soundtrack 
          })
        });
        /* add strassenlärm to currentTracks - hardcoded */
        let tracks = state.currentTracks;
        tracks.splice(0, 1, selectableSourcesLeft[selectableSourcesRight.length-2]);
        Vue.set(state, 'currentTracks', tracks);
        /* */
        Vue.set(state, 'selectableSourcesLeft', selectableSourcesLeft);
        Vue.set(state, 'selectableSourcesRight', selectableSourcesRight);
        break;
      }
    }
  },

  setCurrentTrack(state, payload) { 
    let tracks = state.currentTracks;
    // tracks[payload.index] = payload.source;
    tracks.splice(payload.index, 1, payload.source);
    Vue.set(state, 'currentTracks', tracks);
  },

  addRecordToSelectabalSources(state, payload) {
    let sources = state.selectableSourcesRight;
    if (sources.find(item => item.type === 'record')) {
      sources.map(item => item.type === 'record' ? payload : item);
    } else {
      sources.push(payload);
    }
    Vue.set(state, 'selectableSourcesRight', sources);
  },

  updateScreenState(state, payload) {
    Vue.set(state, 'screenState', payload);
  },

  setViewBlocked(state, payload) {
    Vue.set(state, 'viewBlocked', payload);
  },

  updateStartTimestamp(state, payload) {
    Vue.set(state, 'stationStartTimestamp', payload);
  },

  updateEndTimestamp(state, payload) {
    Vue.set(state, 'stationEndTimestamp', payload);
  },

  updateClickCounter(state) {
    Vue.set(state, 'clickCount', state.clickCount + 1);
  },

  setMediaId(state, payload) {
    let mediaIds = state.mediaIds;

    mediaIds[payload.index] = payload.id;
    
    Vue.set(state, 'mediaIds', mediaIds);
  },

  addScore(state, payload) {
    let scores = state.scores;
    scores.push(payload);
    Vue.set(state, 'scores', scores);
  }

};