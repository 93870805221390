<script>
export default {
  name: 'ScoreIndicator',
  props: [
    'score'
  ],
  computed: {
    barPos() {
      if(!this.score) return 1;
      return 1 - (this.score / 100);
    }
  },
  methods: {
    
  }
};
</script>

<template>
  <transition name="fade">
    <div class="score-wrap">
      <span class="label">{{ $t('composer.scoreIndicatorTitle') }}</span>
      <div class="score">
        <div class="score__bar">
          <span v-bind:style="{ transform: 'scale(' + barPos + ', 1)' }"></span>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="scss">
  @import '../assets/styles/variables';

  .score-wrap {
    .label {
      display: block;
      text-align: center;
      font-size: 1.1rem;
      padding-bottom: 1rem;
    }
  }
  .score {
    position: relative;

    &__bar {
      position: relative;
      width: 100%;
      height: 60px;
      border: 4px solid var(--light);
      background-image: linear-gradient(270deg, #B3E4C9 0%, #FBEBA1 50%, #F9C2B8 100%);
      box-sizing: border-box;

      span {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: var(--light);
        opacity: .6;
        transform-origin: 100% 0;
        transition: 2s transform cubic-bezier(0.190, 1.000, 0.220, 1.000);
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

</style>
