import axios from 'axios';

export default {
  initApp(context, payload) {
    context.commit('initializeApp', payload);
  },
  loadCmsData(context) {
    fetch(`${window.cmsUrl}/wp-json/wp/v2/stations?slug=${window.cmsSlug}&lang=${context.state.lang}`)
      .then(data => data.json())
      .then(data => context.commit('loadCmsDataSuccess', data[0]))
      .catch(err => context.commit('loadCmsDataFailed', err))
  },
  loadVisitorData(context, payload) {
    fetch(`${window.apiUrl}/api/visitors/${payload}`)
      .then(data => data.json())
      .then(data => context.commit('loadVisitorDataSuccess', data))
      .catch(err => context.commit('loadVisitorDataFailed', err))
  },

  nextStep(context) {
    context.commit('updateCurrentStep');
  },

  saveRecord(context, payload) {
    context.commit('setViewBlocked', true);
    let step = context.getters.getCurrentStep;
    let index = step;
    let comparableId;
    if(step === 3 || step === 4) {
      let tracks = context.getters.getCurrentTracks;
      if (tracks[0]) {
        index += "-" + tracks[0].id;
        comparableId = tracks[0].id;
      } 
    }
    
    let data = new FormData();
    data.append('title', payload.title);
    data.append('station_id', 6);
    data.append('visitor_id', payload.visitorId);
    data.append('media', payload.media);

    if(comparableId) data.append("comparable", comparableId);

    let mediaIds = context.getters.getMediaIds;
    if(mediaIds[index]) data.append('media_id', mediaIds[index]);

    let config = {
      header : { 'Content-Type': `multipart/form-data; boundary=${data._boundary}`, }
    }

    return axios.post(`${window.apiUrl}/api/media/`, data, config)
      .then(response => {
        let source = {
          id: response.data.id,
          type: 'record', 
          title: 'Meine Aufnahme',
          img: payload.img, 
          src: response.data.mp3_url 
        }
        context.commit('addRecordToSelectabalSources', source);
        context.commit('setCurrentTrack', { index: 0, source: source });
        if(!mediaIds[index]) context.commit('setMediaId', { index: index, id: response.data.id });
        context.commit("setViewBlocked", false);
      })
      // .catch(err => context.commit('saveRecordFailed', err))
  },
  setTrack(context, payload) {
    context.commit('setCurrentTrack', payload);
  },

  clickCount(context) {
    context.commit('updateClickCounter');
  },

  startStation(context) {
    context.commit('updateScreenState', 'composer');
    context.commit('updateCurrentStep', 1);
    context.commit('updateStartTimestamp', Date.now());
  },
  exitStation(context) {
    // context.commit('updateScreenState', 'end');
    context.commit('updateEndTimestamp', Date.now());
    context.dispatch('sendStatistics');
  },
  
  sendStatistics(context) {
    context.commit('updateEndTimestamp', Date.now());
    let stats = context.getters.getStationStatisticsTotals;
    let visitorId = context.getters.getVisitorId;

    axios.post(`${window.apiUrl}/api/scores/`, {
      station_id: 6,
      visitor_id: visitorId,
      score: stats.score,
      duration: stats.duration,
      activity: stats.activity
    })
    .then(data => {
      console.log(data);
      window.postMessage('end');
    })
    .catch(err => {
      console.log(err);
    });
    
  }
};