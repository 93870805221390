import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    visitorId: null,
    visitorData: null,
    visitorDataError: null,
    lang: null,
    cmsDataLoading: true,
    cmsDataError: null,
    cmsData: null,
    currentStep: 1,
    selectableSourcesLeft: [],
    selectableSourcesRight: [],
    currentTracks: [null, null],
    stationStartTimestamp: null,
    stationEndTimestamp: null,
    clickCount: 0,
    screenState: "composer",
    mediaIds: {},
    scores: [],
    viewBlocked: false
  },
  actions,
  getters,
  mutations
});