import { normalizeValue } from "../helpers.js";

export default {
  getVisitorId: state => {
    return state.visitorId;
  },
  getVisitorAvatar: state => {
    if (state.visitorData)
      return state.visitorData.avatar_url;
  },
  getLang: state => {
    return state.lang;
  },

  getCmsDataLoading: state => {
    return state.cmsDataLoading;
  },
  getCmsDataError: state => {
    return state.cmsDataError;
  },
  getCmsData: state => {
    return state.cmsData;
  },

  getStationIntroTrack: state => {
    if (state.cmsData)
      return state.cmsData.intro_track;
  },
  getStationHeading: state => {
    if (state.cmsData)
      return state.cmsData.heading;
  },
  getStationScrrenState: state => {
      return state.screenState;
  },
  getStationIntro: state => {
    if (state.cmsData)
      return state.cmsData.intro_text;
  },

  getCurrentVoiceover: state => {
    if (state.cmsData)
      switch (state.currentStep) {
        case 1:
          return state.cmsData.voiceover_1;

        case 2:
          return state.cmsData.voiceover_2;

        case 3:
          return state.cmsData.voiceover_3;

        case 4:
          return state.cmsData.voiceover_4;
      
        default:
          return null;
      }
      
  },
  
  getCurrentStep: state => {
    return state.currentStep;
  },
  getViewBlocked: state => {
    return state.viewBlocked;
  },
  getSelectableSourcesLeft: state => {
    return state.selectableSourcesLeft;
  },
  getSelectableSourcesRight: state => {
    return state.selectableSourcesRight;
  },
  getCurrentTracks: state => {
    return state.currentTracks;
  },
  getMediaIds: state => {
    return state.mediaIds;
  },
  getScores: state => {
    return state.scores;
  },
  getStartInstructions: state => {
    if (state.cmsData)
      return state.cmsData.start_instructions;
  },

  getBirdSounds: state => {
    if (state.cmsData)
      return state.cmsData.birds;
  },
  getBackgroundSounds: state => {
    if (state.cmsData)
      return state.cmsData.background_sounds;
  },

  getStationStatisticsTotals: state => {
    let duration = 0,
      clicks = 0,
      activity = 0,
      score = 0;

    if (state.stationStartTimestamp && state.stationEndTimestamp) {
      duration = state.stationEndTimestamp - state.stationStartTimestamp
      duration = normalizeValue(duration, 0, 600000);
    }

    let scores = state.scores;
    if(scores.length > 0) {
      let scoreSum = scores.reduce((acc, a) => {
        return acc + a;
      });
      score = Math.round(scoreSum / scores.length) / 100;
    }

    clicks = state.clickCount;
    activity = normalizeValue(clicks, 0, 50);

    return { duration, activity, score };
  },
  
};