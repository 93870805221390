<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EndPage',
  methods: {
    unloadStation() {
      window.postMessage('end');
    }
  },
  computed: {
    ...mapGetters({
      levelStatisticsTotals: 'getStationStatisticsTotals',
    }),
  }
}
</script>

<template>
  <div class="end-page">
    <div class="end-page__content">
      <h2 class="end-page__title">Station abgeschlossen</h2>
      <h3>Score</h3>
      <dl class="end-page__scores">
        <dt>Total Answered</dt>
        <dd>{{levelStatisticsTotals.total}}</dd>
        <dt>Correct Answered</dt>
        <dd>{{levelStatisticsTotals.correct}}</dd>
        <dt>Wrong Answered</dt>
        <dd>{{levelStatisticsTotals.wrong}}</dd>
        <dt>Duration</dt>
        <dd>{{levelStatisticsTotals.duration | formatDate}}</dd>
        <dt>Clicks</dt>
        <dd>{{levelStatisticsTotals.clicks}}</dd>
      </dl>
    </div>

    <div class="end-page__actions">
      <button class="button" v-on:click="unloadStation()">Neue Station laden</button>
    </div>

  </div>
</template>

<style scoped lang="scss">
@import '../assets/styles/variables';
@import '../assets/styles/common';

.end-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

.end-page__title {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
}

.end-page__scores {
  padding: 1rem;
  font-family: var(--font-family-secondary);
  background-color: rgba(black, 0.1);
}
</style>
