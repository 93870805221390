<script>
import { mapGetters } from 'vuex';
import { Howl } from 'howler';
import Player from 'ne01-common/src/components/Player';
import InfoButton from 'ne01-common/src/components/InfoButton';
import SourceSelector from '../components/SourceSelector.vue';
import ScoreIndicator from '../components/ScoreIndicator.vue';

export default {
  name: 'ComposerPage',
  components: {
    SourceSelector,
    Player,
    InfoButton,
    ScoreIndicator
  },
  data: () => {
    return {
      totalSteps: 4,
      playerId: 1,
      isReadyForNextStep: false,
      hasRecorded: false,
      compareScore: 0,
      recordIds: []
    }
  },
  mounted() {
    document.addEventListener('click', this.countClick);
    this.playVoiceOver();
  },
  watch: {
  },
  methods: { 
    setTrack(source, index) {
      this.$store.dispatch('setTrack', {index: index, source: source});
    },
    handleRecording(src) {      
      let data = {
        title: '',
        visitorId: this.visitorId,
        media: src,
        img: this.visitorAvatar
      };

      if (this.currentStep === 1) {
        data.title = this.$i18n.t('composer.firstRecord');
      } else {
        if (this.tracks[0]) {
          data.title = this.tracks[0] ? this.tracks[0].title : '';
          data.title += this.tracks[1] ? ' – ' + this.tracks[1].title : '';
        }
      }

      this.$store.dispatch('saveRecord', data);
    },
    nextStep() {
      this.$store.dispatch('nextStep');
      this.isReadyForNextStep = false;

      this.playVoiceOver();
      
    },
    playVoiceOver() {
      if (this.currentVoiceover) {
        this.sound = new Howl({ 
          src: [ this.currentVoiceover ], 
          autoplay: true, 
          loop: false,
          onend: () => {
            this.isReadyForNextStep = true;
          }
        });
      }
    },
    exitStation() {
      this.$store.commit('setViewBlocked', true);
      this.$store.dispatch('exitStation');
    },
    countClick() {
      if (!this.stationEnd)
        this.$store.dispatch('clickCount');
    },
    handleScore(s) {
      if(!s) return;
      this.$store.commit('addScore', s);
      this.compareScore = s;
    }
  },
  computed: {
    ...mapGetters({
      instruction: 'getStartInstructions',
      currentStep: 'getCurrentStep',
      birdSounds: 'getBirdSounds',
      backgroundSounds: 'getBackgroundSounds',
      selectableSourcesLeft: 'getSelectableSourcesLeft',
      selectableSourcesRight: 'getSelectableSourcesRight',
      visitorId: 'getVisitorId',
      tracks: 'getCurrentTracks',
      currentVoiceover: 'getCurrentVoiceover',
      visitorAvatar: 'getVisitorAvatar',
      scores: 'getScores',
      viewBlocked: 'getViewBlocked',
      stationData: 'getCmsData',
    }),
    stationEnd() {      
      return this.currentStep > this.totalSteps;
    },
    playerSrc() {
      return [this.tracks[0] ? this.tracks[0].src : null, this.tracks[1] ? this.tracks[1].src : null];
    }
  },
  destroyed() {
    document.removeEventListener('click', this.countClick);
  }
}
</script>

<template>
  <div class="composer">
    <span class="block-overlay" v-bind:class="{visible: viewBlocked}"></span>
    <div class="composer__content">
      <div class="composer__header">
        <p class="composer__instruction" v-if="currentStep === 1">{{instruction}}</p>
      </div>
      <div class="composer__library" v-if="currentStep > 1">
        <source-selector 
          class="composer__sources composer__sources--first" 
          v-bind:caption="$t('composer.srcSelectorLeftCaption')"
          v-bind:sources="selectableSourcesLeft" 
          v-bind:selectedSource="tracks[0]" 
          v-on:selected="setTrack($event, 0)"
          >
        </source-selector>
        <source-selector 
          class="composer__sources composer__sources--second" 
          v-bind:caption="$t('composer.srcSelectorRightCaption')"
          v-bind:sources="selectableSourcesRight" 
          v-bind:selectedSource="tracks[1]" 
          v-on:selected="setTrack($event, 1)"
          isColorHighlighted
          isRight>
        </source-selector>
      </div>

      <div class="player-wrap">
        <player 
          class="composer__player" 
          v-bind:src="playerSrc" 
          v-on:recordend="handleRecording" 
          record spectroImg controls showProgress 
          v-bind:speeds="[100,50,20]" 
          @score="handleScore"
          v-bind:invertScore="currentStep === 4"
          />
        <span v-if="currentStep === 1" class="indicator-record"></span>
      </div>

      <score-indicator v-if="currentStep === 3" :score="compareScore" />

      <transition name="fade">
        <div v-if="currentStep === 1" class="infobutton">
          <InfoButton 
            v-bind:key="stationData.info_page_step_1.ID" 
            v-bind:id="stationData.info_page_step_1.ID" 
            v-bind:title="stationData.info_page_step_1.post_title" 
          />
        </div>
        <div v-if="currentStep === 3 && isReadyForNextStep" class="infobutton">
          <InfoButton 
            v-bind:key="stationData.info_page_step_3.ID" 
            v-bind:id="stationData.info_page_step_3.ID" 
            v-bind:title="stationData.info_page_step_1.post_title" 
          />
        </div>
        <div v-if="currentStep === 4 && isReadyForNextStep" class="infobutton">
          <InfoButton 
            v-bind:key="stationData.info_page_step_4.ID" 
            v-bind:id="stationData.info_page_step_4.ID" 
            v-bind:title="stationData.info_page_step_1.post_title" 
          />
        </div>
      </transition>
    </div>

    <div class="composer__actions" >
      <div v-if="isReadyForNextStep">
        <button 
          class="composer__next-step common-button" 
          v-if="currentStep < totalSteps"
          v-on:click="nextStep()">
            {{$t('composer.next')}}
        </button>
        <button 
          class="composer_station-end common-button" 
          v-if="currentStep >= totalSteps"
          v-on:click="exitStation()">
            {{$t('composer.exitStation')}}
        </button>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
@import '../assets/styles/variables';
@import '../assets/styles/common';

.composer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

.composer__header {
  margin-bottom: 4rem;
}
.composer__instruction {
  font-size: 1.5rem;
  font-family: var(--font-family-secondary);
  text-align: center;
}

.player-wrap {
  position: relative;
  margin: 3rem auto;
}

.composer__library {
  display: flex;
  justify-content: space-between;
}

.composer__sources {
  width: 48%;
}

.indicator-record {
  display: block;
  width: 34px;
  height: 50px;
  background-image: url('../assets/images/arrow-white.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -55px;
  left: 79px;
  z-index: 2;
  opacity: 0;
  animation: arrow-1 2s ease-in-out  5s 5 backwards;
}

@keyframes arrow-1 {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

.block-overlay {
  display: block;
  position: absolute;
  top: 5.25rem;
  left: 0;
  width: 100%;
  height: calc(100% - 5.25rem);
  background-color: var(--dark);
  z-index: 3;
  opacity: 0;
  pointer-events: none;
  transition: .1s opacity;

  &.visible {
    opacity: .05;
    pointer-events: auto;
  }
}

.composer__actions {
  margin-top: 4rem;
}

.infobutton {
  margin-top: 5rem;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>
