import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { commonMessages } from "ne01-common/src/i18n";

Vue.use(VueI18n);

const messages = {
  de: {
    composer: {
      next: "Weiter",
      exitStation: "Station beenden",
      srcSelectorLeftCaption: 'Sonagramm 1',
      srcSelectorRightCaption: 'Sonagramm 2',
      firstRecord: 'Erste Aufnahme',
      myRecord: 'Meine Aufnahme',
      noSource: 'Keine Aufnahme',
      selectRecord: 'Wähle eine Aufnahme',
      scoreIndicatorTitle: 'Wie gut ist deine Imitation?'
    },
    ...commonMessages.de
  },
  fr: {
    composer: {
      next: "Continuer",
      exitStation: "Quitter la station",
      srcSelectorLeftCaption: 'Sonagramme 1',
      srcSelectorRightCaption: 'Sonagramme 2',
      firstRecord: 'Premier enregistrement',
      myRecord: 'Mon enregistrement',
      noSource: 'Pas d\'enregistrement',
      selectRecord: 'Choisir enregistrement',
      scoreIndicatorTitle: 'Quelle est la qualité de votre imitation ?'
    },
    ...commonMessages.fr
  }
};

const i18n = new VueI18n({
  locale: 'de',
  messages,
});

export default i18n;