<script>
export default {
  name: 'ErrorMessage',
  props: [
    'error'
  ],
  methods: {
    onReloadClicked() {
      this.$emit('reloadData');
    }
  }
};
</script>

<template>
  <div class="error" v-if="error">
    <article class="error__content">
      <h2 class="error__title">Es ist ein Fehler aufgetreten</h2>
      <p class="error__message">{{ error.message }}</p>
    </article>
    <footer class="error__footer container">
      <button class="button" v-on:click="onReloadClicked()">Nochmals versuchen</button>
    </footer>
  </div>
</template>

<style scoped lang="scss">
  @import '../assets/styles/variables';
  @import "../assets/styles/common";

  .error {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-width: 45rem;
    margin: auto;
    text-align: center;
  }

  .error__content,
  .error__footer {
    @extend .container;
    @extend .container--center;
  }

  .error__title {
    font-size: 4.5em;
    line-height: 1.2;
  }

  .error__message {
    font-size: 2em;
  }

  .error__footer {
    padding: 1.5rem 0 4.5rem;
  }
</style>
