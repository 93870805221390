<script>
export default {
  name: 'LoadIndicator',
};
</script>

<template>
  <div class="spinner">
    <div></div>
    <div></div>
  </div>
</template>

<style scoped lang="scss">
  @import '../assets/styles/variables';
  @import "../assets/styles/common";

  .spinner {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }

  .spinner div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: spinner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  .spinner div:nth-child(2) {
    animation-delay: -0.5s;
  }

  @keyframes spinner {
    0% {
      top: 28px;
      left: 28px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 58px;
      height: 58px;
      opacity: 0;
    }
  }

</style>
