<script>
export default {
  name: 'SourceSelector',
  props: {
    selectedSource: Object,
    sources: Array,
    caption: String,
    isRight: Boolean,
    isColorHighlighted: Boolean,
  },
  data: () => {
    return {
      isExpanded: false,
      sound: null,
    }
  },
  mounted() {
    
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    onSelect(option) {
      this.$emit('selected', option)
    }
  },
  computed: {
    currentLabel() {
      return this.selectedSource ? this.selectedSource.title : this.$t('composer.selectRecord');
    },
    currentImg() {
      return this.selectedSource ? this.selectedSource.img : '';
    }
  }
};
</script>

<template>
  <div class="slector" v-bind:class="{'selector--expanded': isExpanded, 'selector--right': isRight, 'selector--color-hi': isColorHighlighted}" v-on:click="toggleExpand()">
    <div class="selector__button">
      <div class="selector__img" v-bind:style="{backgroundImage: 'url(' + currentImg + ')'}"></div>
      <div class="selector__caption">{{caption}}</div>
      <div class="selector__label">{{currentLabel}}</div>
    </div>
    <ul class="selector__sources" >
      <li class="selector__source" v-for="source in sources" :key="source.ID" v-on:click="onSelect(source)">
        <div class="selector__source__img" v-bind:style="{backgroundImage: 'url(' + source.img + ')'}"></div>
        {{source.title}}
      </li>
      <li class="selector__source" v-on:click="onSelect(null)">
        <div class="selector__source__img"></div>
        {{$t('composer.noSource')}}
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.slector {
  position: relative;
  box-sizing: border-box;
  padding: 1rem .6rem 1rem 3.5rem;
  margin:  0;
  background: var(--light);
  font-size: 1.25em;
  font-family: var(--font-family-secondary);
  font-weight: 600;
  line-height: 1;
  color: var(--dark);
  // box-shadow: .5rem .5rem 0 0 var(--dark);
}

.slector:after {
  content: "";
  position: absolute;
  top: 50%; right: 1rem;
  width: .7rem; height: .7rem;
  margin-top: -.5rem;
  border-left: 2px solid var(--dark);
  border-bottom: 2px solid var(--dark);
  transform: rotate(-45deg);
  border-bottom-left-radius: 2px;
}

.selector--color-hi .selector__button {
  color: #00f;
}

.selector--right {
  text-align: right;
  padding: 1rem 3.5rem 1rem .6rem;
}

.selector--right:after {
  left: 1rem;
}

.selector--expanded:after {
  transform: rotate(135deg);
  margin-top: -.25rem;
}

.selector__img {
  display: block;
  position: absolute;
  left: -2rem; top: -1rem;
  width: 5rem; height: 5rem;
  background: #eaeaea;
  border-radius: 50%;
  background-size: cover;
  border: 2px solid var(--light);
  box-sizing: border-box;
}

.selector--right .selector__img {
  left: auto; right: -2rem;
}

.selector__caption {
  font-size: 1rem;
  font-weight: 400;
  opacity: .75;
  margin-bottom: .4rem;
}

.selector__label {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selector__sources {
  display: block;
  position: absolute;
  box-sizing: border-box;
  border-top: 2px solid var(--color-main);
  left: 0;
  width: 100%;
  margin: 1rem 0;
  padding: 0;
  background: rgba(#fffdf9, .95);
  // border: 2px solid var(--dark);
  z-index: 4;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: .3s opacity, .3s transform ease-out;
}

.selector--expanded .selector__sources {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.selector__source {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  display: block;
  height: 4rem;
  font-weight: 400;
  line-height: 4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 .5rem 0 4.5rem;
  border-bottom: 1px solid var(--color-main);
}

.selector--right .selector__source {
  padding: 0 4.5rem 0 .5rem;
}

.selector__source__img {
  position: absolute;
  top: .5rem;
  left: .5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: #eaeaea;
  background-size: cover;
}

.selector--right .selector__source__img {
  left: auto; right: .5rem;
}
</style>
